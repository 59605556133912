import React, { useState } from "react";
import ReactDOM from "react-dom";

import "./styles.css";


function App() {
  // ENTRADA, RODANDO, FIM
  const [estado, setEstado] = useState('ENTRADA')

  // palpite
  const [palpite, setPalpite] = useState(150)
  const [numPalpites, setNumPalpites] = useState(1)

  const [min, setMin] = useState(0)
  const [max, setMax] = useState(301)

  const iniciarJogo = () => {
    setEstado('RODANDO')
    setMin(0)
    setMax(301)
    setNumPalpites(1)
    setPalpite(150)
  }
  if(estado === 'ENTRADA') {
    return (
      <div className="Start">
        <h1>Jogo de Adivinhação</h1>
        <p>Pense em um número de <strong>0</strong> a <strong>300</strong>!</p>
        <button onClick={iniciarJogo}>JOGAR!</button>
      </div>
    )
  }

  const menor = () => {
    setNumPalpites(numPalpites + 1)
    setMax(palpite)
    const proxPalpite = parseInt((palpite - min) / 2, 10) + min
    setPalpite(proxPalpite)
  }

  const maior = () => {
    setNumPalpites(numPalpites + 1)
    setMin(palpite)
    const proxPalpite = parseInt((max - palpite) / 2, 10) + palpite
    setPalpite(proxPalpite)
  }
  
  const acertou = () => {
    setEstado('FIM')
  }

  if (estado === 'FIM') {
    let kick = "chute"
    if (numPalpites > 1) {
      kick = "chutes"
    }
    return (
      <div className="End">
        <p>Acertei o número <strong>{palpite}</strong> com <strong>{numPalpites}</strong> {kick}!</p>
        <button onClick={iniciarJogo}>Iniciar Novamente!</button>
      </div>
    )
  }

  // 0 <> 300
  // palpites que a maquina aqui deu
  return (
    <div className="App">
      <p>O seu número é <strong>{palpite}</strong>?</p>
      <button className="Menor" onClick={menor}>Menor!</button>
      <button className="Maior" onClick={maior}>Maior!</button><br />
      <button className="Acertou" onClick={acertou}>Acertou!</button>
    </div>
  );
}


const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
